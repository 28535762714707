import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40d11237"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "StereoscopicSectionExamShotContainer"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CSGItem = _resolveComponent("CSGItem");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: $data.shot ? $data.shot : $options.defaultSelector,
    onClick: _cache[0] || (_cache[0] = $event => $data.selector = $props.editMode),
    style: _normalizeStyle(`width: ${$props.width}px;height:${$props.width}px`),
    class: "sqr80 ptr"
  }, null, 12, _hoisted_2)]), _createVNode(_component_DialogCustomView, {
    "visible-control": $data.selector,
    width: "90vw",
    title: "捕捉器",
    onClose: _cache[1] || (_cache[1] = $event => $data.selector = false)
  }, {
    default: _withCtx(() => [$data.selector ? (_openBlock(), _createBlock(_component_CSGItem, {
      key: 0,
      "shot-mode": "",
      onClose: $options.handleClose
    }, null, 8, ["onClose"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible-control"])], 64);
}