import SubjectCard from "@/components/dashboard/SubjectCard";
import api from "@/api";
import { mapState, mapMutations } from "vuex";
import { ElMessage } from "element-plus";
export default {
  name: "DashboardView",
  components: {
    SubjectCard
  },
  data() {
    return {
      menus: [],
      activeTab: '代码计算',
      tabIndex: 0,
      subIndex: 0
    };
  },
  computed: {
    ...mapState(['dashboard'])
  },
  methods: {
    ...mapMutations(['updateDashboard']),
    onTabClick(name, mi, si) {
      let setting = {
        activeTab: name,
        tabIndex: mi,
        subIndex: si
      };
      this.updateDashboard(setting);
    },
    init() {
      // setInterval(this.checkServerTime, 5000)
      this.checkServerTime();
      api.getUserInfo().then(ret => {
        this.user = ret.info;
        if ('ADMIN' == ret.info.userTag) {
          localStorage.setItem('waterMarker', '');
        } else {
          localStorage.setItem('waterMarker', `${ret.info.nickName}${ret.info.phone}`);
        }
      });
      api.getUserMenu().then(ret => {
        this.menus = ret;
      });
    },
    checkServerTime() {
      api.getServerTime().then(ret => {
        if (Math.abs(new Date().getTime() - ret) > 5000) {
          ElMessage.error('您的电脑时间不准确与服务器差别较大，会导致计时异常，请校对电脑时间。');
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};