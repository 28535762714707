import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-740d4c2e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "grid-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.grid, (row, rowIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: 'row-' + rowIndex,
      class: "grid-row"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (cell, colIndex) => {
      return _openBlock(), _createElementBlock("div", {
        key: 'cell-' + colIndex,
        class: "grid-cell",
        style: _normalizeStyle({
          backgroundColor: cell === 1 ? 'black' : 'white'
        })
      }, null, 4);
    }), 128))]);
  }), 128))]);
}