import LeftGroupList from "@/components/LeftGroupList";
import api from "@/api";
import LogicalReasoning2DQuestion from "@/components/subject/LogicalReasoning2d/LogicalReasoning2DQuestion.vue";
export default {
  name: "Logical2DQuestionSelector",
  components: {
    LogicalReasoning2DQuestion,
    LeftGroupList
  },
  data() {
    return {
      groups: [],
      groupId: null,
      questions: []
    };
  },
  methods: {
    confirm(qid) {
      this.$emit('confirm', qid);
    },
    onChoose(gid) {
      this.groupId = gid;
      this.fetchList();
    },
    fetchList() {
      api.getLogical2dQuestionByGroupId(this.groupId).then(ret => {
        this.questions = ret.list;
      });
    },
    init() {
      api.getLogical2dGroups().then(list => {
        this.groups = list;
        this.groupId = list[0].value;
        this.fetchList();
      });
    }
  },
  mounted() {
    this.init();
  }
};