import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-08f1dec1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Logical2DQuestionEditorContainer"
};
const _hoisted_2 = {
  class: "mr-2"
};
const _hoisted_3 = {
  class: "mr-2"
};
const _hoisted_4 = {
  key: 1,
  class: "bothCenter mt16 wd100",
  style: {
    "height": "400px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_LogicalReasoning2DQuestion = _resolveComponent("LogicalReasoning2DQuestion");
  const _component_Logical2DQuestionSelector = _resolveComponent("Logical2DQuestionSelector");
  const _component_el_drawer = _resolveComponent("el-drawer");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.totalQuestionSize, qi => {
    return _openBlock(), _createBlock(_component_el_row, {
      key: `lg_${qi}`,
      class: "wbg p-4",
      style: {
        "border": "#1388ff 1px dashed"
      }
    }, {
      default: _withCtx(() => [$data.questions.length >= qi && $data.questions[qi - 1] ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_el_row, {
        class: "ft14"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_2, "第" + _toDisplayString(qi) + "题 ", 1), _createElementVNode("span", _hoisted_3, "答案：" + _toDisplayString($data.questions[qi - 1].rightAnswer), 1), _createVNode(_component_el_button, {
          type: "danger",
          size: "small",
          onClick: $event => $options.toAdd(qi - 1)
        }, {
          default: _withCtx(() => [_createTextVNode("换题")]),
          _: 2
        }, 1032, ["onClick"])]),
        _: 2
      }, 1024), _createVNode(_component_LogicalReasoning2DQuestion, {
        question: $data.questions[qi - 1]
      }, null, 8, ["question"])], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_button, {
        size: "large",
        type: "primary",
        onClick: $event => $options.toAdd(qi - 1)
      }, {
        default: _withCtx(() => [_createTextVNode("添加试题")]),
        _: 2
      }, 1032, ["onClick"])]))]),
      _: 2
    }, 1024);
  }), 128))]), _createVNode(_component_el_drawer, {
    modelValue: $data.drawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.drawer = $event),
    title: "选择题目",
    size: "1300px",
    "with-header": false
  }, {
    default: _withCtx(() => [_createVNode(_component_Logical2DQuestionSelector, {
      onConfirm: $options.onSelected
    }, null, 8, ["onConfirm"])]),
    _: 1
  }, 8, ["modelValue"])], 64);
}