import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.every.js";
// import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
import { stringify } from 'querystring';
import IceCubeItem from "@/components/subject/Cube/IceCubeItem";
import api from "@/api";
export default {
  name: "ThreeRender",
  components: {
    IceCubeItem
  },
  props: {
    subject: {
      type: String,
      default: '-'
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    },
    runtimeParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    initRuntimeParams: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    runtimeParams() {
      this.initData();
      // this.init()
    }
  },
  data() {
    return {
      answer: null,
      positions: [{
        x: 0,
        y: 0,
        z: 0
      }, {
        x: 0,
        y: 0,
        z: 1
      }, {
        x: 0,
        y: 0,
        z: 2
      }],
      activeTab: 'problem',
      demo: [],
      previewData: [],
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    };
  },
  methods: {
    hash(obj) {
      const string = JSON.stringify(obj);
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    },
    initData() {
      const tempData = [...this.previewData];
      if (this.runtimeParams) {
        tempData[this.data.questionIndex] = this.runtimeParams;
      }
      this.previewData = tempData;
    },
    handleSaveImage(imgUrl, index, isDesc) {
      console.log(imgUrl, index, isDesc);
      const tempPreviewData = [...this.previewData];
      if (!tempPreviewData[this.data.questionIndex] || Array.isArray(tempPreviewData[this.data.questionIndex])) {
        tempPreviewData[this.data.questionIndex] = {};
      }
      if (isDesc) {
        tempPreviewData[this.data.questionIndex].question = imgUrl;
      } else {
        if (!tempPreviewData[this.data.questionIndex].answers) {
          tempPreviewData[this.data.questionIndex].answers = [];
        }
        tempPreviewData[this.data.questionIndex].answers[index] = imgUrl;
      }
      console.log(tempPreviewData);
      this.previewData = tempPreviewData;
    },
    saveAll(tplId) {
      console.log('saveAll', tplId, this.previewData);
      api.updateTplRuntimeParams({
        tplId,
        runtimeParams: JSON.stringify(this.previewData, 2)
      }).then(res => {
        ElMessage({
          message: '套题已保存',
          type: 'warning'
        });
      });
    },
    checkIsSaveAll() {
      const currentProblem = this.previewData && this.previewData[this.data.questionIndex];
      if (currentProblem?.question && currentProblem?.answers?.length === this.data.answers.length && Array.from(currentProblem?.answers)?.every(item => !!item)) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.initRuntimeParams) {
      this.previewData = this.initRuntimeParams;
    }
    this.initData();
  }
};