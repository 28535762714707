import DialogCustomView from "@/components/DialogCustomView.vue";
import CSGItem from "@/components/subject/CSG/CSGItem.vue";
export default {
  name: "StereoscopicSectionExamShot",
  components: {
    CSGItem,
    DialogCustomView
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      shot: null,
      selector: false
    };
  },
  computed: {
    defaultSelector() {
      return this.img ? this.img : 'https://cdn.waityou.online/526489b6-5897-004b-187e-ad76d05ef2a8.svg';
    }
  },
  methods: {
    handleClose(url) {
      this.shot = url;
      this.$emit('saveImg', url);
      this.selector = false;
    },
    init() {
      console.log('defaultSelector', this.defaultSelector);
    }
  },
  mounted() {
    this.init();
  }
};