export default {
  name: "LogicalReasoning2DImg",
  props: {
    imgData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      imgs: {
        0: ['https://cdn.waityou.online/87fd0525-1874-3370-bb1d-b1ddefddd319.svg', 'https://cdn.waityou.online/3225e904-3cc3-4087-7fe0-a099ad43448d.svg'],
        1: ['https://cdn.waityou.online/f79a8d3b-5e3c-3e14-f20e-889d9347a7bf.svg', 'https://cdn.waityou.online/7ff07c40-e21f-4a1b-75c9-85bada559308.svg'],
        2: ['https://cdn.waityou.online/47f94195-6ddf-6d3b-a202-fb5317ecfbd6.svg', 'https://cdn.waityou.online/43a46521-706a-a095-23f8-8773eb34bb67.svg'],
        3: ['https://cdn.waityou.online/79a2d6a7-f019-6b41-d188-933224b5ca1b.svg', 'https://cdn.waityou.online/4a75ea44-e546-e0c0-b0af-2e74a65dbe46.svg']
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};