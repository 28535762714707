import api from "@/api";
import Unfolding3DSelector from "@/components/editor/Unfolding3DSelector";
export default {
  name: "Unfolding3DEditor",
  components: {
    Unfolding3DSelector
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      questions: [],
      drawer: false,
      updateIndex: 0
    };
  },
  methods: {
    toAdd(idx) {
      this.updateIndex = idx;
      this.drawer = true;
    },
    onSelected(qid) {
      api.updateTplQuestionId({
        tplId: this.data.id,
        updateIndex: this.updateIndex,
        questionId: qid
      }).then(() => {
        this.drawer = false;
        this.fetchQuestions();
      });
    },
    fetchQuestions() {
      api.previewTplQuestion(this.data.id).then(ret => {
        this.questions = ret;
      });
    },
    init() {
      this.fetchQuestions();
    }
  },
  mounted() {
    this.init();
  }
};