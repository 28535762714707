import AnswerButtonGroup from "@/components/train/AnswerButtonGroup.vue";
export default {
  name: "StereoscopicSectionExamRender",
  components: {
    AnswerButtonGroup
  },
  data() {
    return {};
  },
  props: {
    subject: {
      type: String,
      default: '-'
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    },
    runtimeParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    initRuntimeParams: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    runtimeParams() {
      this.init();
    }
  },
  methods: {
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l[0] : null);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};