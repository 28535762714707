import LogicalTransformerSectorImg from "@/components/subject/LogicalTransformerSector/LogicalTransformerSectorImg.vue";
import LogicalTransformerSectorAnimate from "@/components/subject/LogicalTransformerSector/LogicalTransformerSectorAnimate.vue";
import AnswerButtonGroup from "@/components/train/AnswerButtonGroup.vue";
export default {
  name: "LogicalTransformerSectorRender",
  components: {
    AnswerButtonGroup,
    LogicalTransformerSectorAnimate,
    LogicalTransformerSectorImg
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    plus: {
      type: Boolean,
      default: false
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    question() {
      this.$refs.LogicalTransformerSectorRenderAnswer.init();
      this.init();
    }
  },
  data() {
    return {
      countDown: 10,
      countDownTask: null,
      showQuestion: true,
      OPTIONS: ['A', 'B', 'C', 'D'],
      transformers: {
        CLOCKWISE_90: "顺时针旋转90度",
        CLOCKWISE_180: "顺时针旋转180度",
        COUNTERCLOCKWISE_90: "逆时针旋转90度",
        COUNTERCLOCKWISE_180: "逆时针旋转180度",
        FLIP_LEFT_RIGHT: "左右翻转",
        FLIP_UP_DOWN: "上下翻转"
      }
    };
  },
  methods: {
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l[0] : null);
    },
    init() {
      if (this.plus) {
        this.countDown = 10;
        this.showQuestion = true;
        this.countDownTask = setInterval(() => {
          if (this.countDown-- <= 0) {
            this.showQuestion = false;
            clearInterval(this.countDownTask);
          }
          console.log(this.countDown);
        }, 1000);
      } else {
        this.showQuestion = true;
      }
    }
  },
  mounted() {
    this.init();
  }
};