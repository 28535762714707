import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7a3a00b9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "SeesawLoadContainer"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.data.length == 1 ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $data.mapping[$props.data],
    class: "sqr30 vpd2"
  }, null, 8, _hoisted_2)) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList(parseInt($props.data[0]), i => {
    return _openBlock(), _createElementBlock("img", {
      src: $data.mapping[$props.data[1]],
      class: "sqr30 vpd2",
      key: `${$props.data}_${i}`
    }, null, 8, _hoisted_3);
  }), 128))]);
}