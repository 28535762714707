import api from "@/api";
import { ElMessage } from "element-plus";
import StereoscopicSectionExamShot from "@/components/editor/StereoscopicSectionExamShot.vue";
export default {
  name: "StereoscopicSectionExamEditor",
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    subject: {
      type: String,
      default: 'StereoscopicSectionExam'
    },
    editMode: {
      type: Boolean,
      default: false
    },
    runtimeParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  components: {
    StereoscopicSectionExamShot
  },
  watch: {
    runtimeParams() {
      this.init();
    }
  },
  data() {
    return {
      previewData: []
    };
  },
  methods: {
    init() {
      const tempData = [...this.previewData];
      if (this.runtimeParams) {
        tempData[this.data.questionIndex] = this.runtimeParams;
      }
      this.previewData = tempData;
      console.log('previewData', this.previewData);
    },
    handleSaveImage(imgUrl, option) {
      console.log(imgUrl);
      const tempPreviewData = [...this.previewData];
      if (!tempPreviewData[this.data.questionIndex]) {
        tempPreviewData[this.data.questionIndex] = {
          options: {}
        };
      }
      if (!tempPreviewData[this.data.questionIndex].options) {
        tempPreviewData[this.data.questionIndex].options = {};
      }
      if (option == 'Q') {
        tempPreviewData[this.data.questionIndex].question = imgUrl;
      } else {
        tempPreviewData[this.data.questionIndex].options[option] = imgUrl;
      }
      console.log(tempPreviewData);
      this.previewData = tempPreviewData;
    },
    saveAll(tplId) {
      console.log('saveAll', tplId, this.previewData, JSON.stringify(this.previewData));
      api.updateTplRuntimeParams({
        tplId,
        runtimeParams: JSON.stringify(this.previewData)
      }).then(() => {
        ElMessage({
          message: '套题已保存',
          type: 'warning'
        });
      });
    },
    checkIsSaveAll() {
      return this.previewData && this.previewData[this.data.questionIndex].question && this.previewData[this.data.questionIndex].options && Object.keys(this.previewData[this.data.questionIndex].options).length == 4;
    }
  },
  mounted() {
    this.init();
  }
};