import ThreeRender from "@/components/subject/ThreeProblem/ThreeRender";
import E808BRender from "@/components/subject/E808B/E808BRender";
import LogicalReasoningRender from "@/components/subject/LogicalReasoning/LogicalReasoningRender";
import FlashDiamondRender from "@/components/subject/FlashDiamond/FlashDiamondRender";
import ImageTransformRender from "@/components/subject/ImageTransform/ImageTransformRender";
import WhackAMoleRender from "@/components/subject/WhackAMole/WhackAMoleRender";
import E808APlusRender from "@/components/subject/E808APlus/E808APlusRender";
import MossRender from "@/components/subject/Moss/MossRender";
import E808EPlusRender from "@/components/subject/E808EPlus/E808EPlusRender";
import E808DRender from "@/components/subject/E808D/E808DRender";
import E808B2Render from "@/components/subject/E808B2/E808B2Render";
import E808CRender from "@/components/subject/E808C/E808CRender";
import QuickScaleRender from "@/components/subject/QuickScale/QuickScaleRender";
import E808D2Render from "@/components/subject/E808D2/E808D2Render";
import E808B3Render from "@/components/subject/E808B3/E808B3Render";
import CodeMathRender from "@/components/subject/CodeMath/CodeMathRender";
import FastMemoryRender from "@/components/subject/FastMemory/FastMemoryRender";
import SeesawRender from "@/components/subject/Seesaw/SeesawRender";
import { ElMessage } from "element-plus";
import BlockRotateRender from "@/components/subject/BlockRotate/BlockRotateRender";
import CubeComplementRender from "@/components/subject/CubeComplement/CubeComplementRender";
import FillWallRender from "@/components/subject/FillWall/FillWallRender";
import RandomForestRender from "@/components/subject/RandomForest/RandomForestRender.vue";
import BlackWhiteDotRender from "@/components/subject/BlackWhiteDot/BlackWhiteDotRender.vue";
import GroundPerspectiveRender from "@/components/subject/GroundPerspective/GroundPerspectiveRender.vue";
import PriceCalculateRender from "@/components/subject/PriceCalculate/PriceCalculateRender.vue";
import Unfolding3DRender from "@/components/subject/Unfolding3D/Unfolding3DRender.vue";
import FlashBoxRender from "../subject/FlashBox/FlashBoxRender.vue";
import DiceRender from "../subject/Dice/DiceRender.vue";
import NineGridRender from "../subject/NineGrid/NineGridRender.vue";
import MemoryNumbersRender from "../subject/MemoryNumbers/MemoryNumbersRender.vue";
import GraphicalReasoningRender from "../subject/GraphicalReasoning/GraphicalReasoningRender.vue";
import LogicalReasoning2DRender from "@/components/subject/LogicalReasoning2d/LogicalReasoningRender.vue";
import LogicalTransformer2D from "@/components/subject/LogicalTransformer2D/LogicalTransformer2D.vue";
import LogicalTransformerSectorRender from "@/components/subject/LogicalTransformerSector/LogicalTransformerSectorRender.vue";
export default {
  name: "QuestionReviewRenderPage",
  components: {
    LogicalTransformerSectorRender,
    LogicalTransformer2D,
    LogicalReasoning2DRender,
    Unfolding3DRender,
    PriceCalculateRender,
    GroundPerspectiveRender,
    BlackWhiteDotRender,
    RandomForestRender,
    SeesawRender,
    FastMemoryRender,
    CodeMathRender,
    E808B3Render,
    E808D2Render,
    QuickScaleRender,
    E808CRender,
    E808B2Render,
    E808DRender,
    E808EPlusRender,
    E808BRender,
    MossRender,
    ThreeRender,
    FillWallRender,
    BlockRotateRender,
    CubeComplementRender,
    E808APlusRender,
    WhackAMoleRender,
    ImageTransformRender,
    FlashDiamondRender,
    LogicalReasoningRender,
    FlashBoxRender,
    DiceRender,
    NineGridRender
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null;
      }
    },
    subject: {
      type: String,
      default: ''
    },
    jumpToIndex: {
      type: Number,
      default: 0
    },
    groupSize: {
      type: Number,
      default: 1
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    jumpToIndex(nv) {
      this.reviewIndex = nv;
    }
  },
  data() {
    return {
      reviewIndex: 0
    };
  },
  methods: {
    reGenerate(f, t) {
      this.$emit('reGenerate', f, t);
    },
    init() {},
    async handleSave() {
      const tplId = this.$route.params.tplId;
      if (['BlockRotate', 'FillWall', 'CubeComplement', 'GroundPerspective', 'RandomForest'].includes(this.subject) && this.editMode) {
        if (!this.$refs.threeRenderRef.checkIsSaveAll()) {
          ElMessage.error('本题有未保存的图片，请检查');
          return;
        }
        await this.$refs.threeRenderRef.saveAll(tplId);
      }
      if (this.subject == 'RandomDice' && this.editMode) {
        if (!this.$refs.diceRenderRef.checkIsSaveAll()) {
          ElMessage.error('本题有未保存的图片，请检查');
          return;
        }
        await this.$refs.diceRenderRef.saveAll(tplId);
      }
      if (this.subject == 'NeuralNetwork' && this.editMode) {
        if (!this.$refs.neuralNetworkRef.checkIsSaveAll()) {
          ElMessage.error('本题未编辑完善，请检查');
          return;
        }
        this.$refs.neuralNetworkRef.saveAll(tplId);
      }
      if (this.subject == 'StereoscopicSectionExam' && this.editMode) {
        if (!this.$refs.StereoscopicSectionExamEditor.checkIsSaveAll()) {
          ElMessage.error('本题未编辑完善，请检查');
          return;
        }
        this.$refs.StereoscopicSectionExamEditor.saveAll(tplId);
      }
      this.$router.go(-1);
    },
    handleNext() {
      if (['BlockRotate', 'FillWall', 'CubeComplement', 'GroundPerspective', 'RandomForest'].includes(this.subject) && this.editMode) {
        if (!this.$refs.threeRenderRef.checkIsSaveAll()) {
          ElMessage.error('本题有未保存的图片，请检查');
          return;
        }
        this.$refs.threeRenderRef.saveAll(this.$route.params.tplId);
      }
      if (this.subject == 'RandomDice' && this.editMode) {
        if (!this.$refs.diceRenderRef.checkIsSaveAll()) {
          ElMessage.error('本题有未保存的图片，请检查');
          return;
        }
        this.$refs.diceRenderRef.saveAll(this.$route.params.tplId);
      }
      if (this.subject == 'NeuralNetwork' && this.editMode) {
        if (!this.$refs.neuralNetworkRef.checkIsSaveAll()) {
          ElMessage.error('本题未编辑完善，请检查');
          return;
        }
        this.$refs.neuralNetworkRef.saveAll(this.$route.params.tplId);
      }
      if (this.subject == 'StereoscopicSectionExam' && this.editMode) {
        if (!this.$refs.StereoscopicSectionExamEditor.checkIsSaveAll()) {
          ElMessage.error('本题未编辑完善，请检查');
          return;
        }
        this.$refs.StereoscopicSectionExamEditor.saveAll(this.$route.params.tplId);
      }
      this.reviewIndex++;
    }
  },
  mounted() {
    this.init();
  }
};