export default {
  name: "LogicalTransformerSectorAnimate",
  components: {},
  props: {
    animates: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      playing: false,
      animateKey: null,
      paused: "https://cdn.waityou.online/8db0247d-2b41-5c1e-d9f4-0fe02fbf5445.png",
      animateImage: {
        CLOCKWISE_90: "https://zhixuein-boss.oss-cn-beijing.aliyuncs.com/user-upload/5d45551f-8f78-454b-a1e2-289de7b8fafa.png",
        CLOCKWISE_180: "https://zhixuein-boss.oss-cn-beijing.aliyuncs.com/user-upload/3ab4fcea-f9ff-4424-ae07-1ab1559dcbc4.png",
        COUNTERCLOCKWISE_90: "https://zhixuein-boss.oss-cn-beijing.aliyuncs.com/user-upload/03593b44-e18e-4a7e-97d3-1304d4b7307a.png",
        COUNTERCLOCKWISE_180: "https://zhixuein-boss.oss-cn-beijing.aliyuncs.com/user-upload/3e5021aa-878f-49b0-a94f-4dc01f1880ef.png",
        FLIP_LEFT_RIGHT: "https://zhixuein-boss.oss-cn-beijing.aliyuncs.com/user-upload/525b2d13-7c55-48fd-b2a0-f89b21159451.png",
        FLIP_UP_DOWN: "https://zhixuein-boss.oss-cn-beijing.aliyuncs.com/user-upload/ff17ba17-64b7-4d62-9f85-0e46461c7510.png"
      }
    };
  },
  methods: {
    play() {
      if (this.playing) {
        this.playing = false;
      } else {
        this.animateKey = this.animates[0];
        this.playing = true;
        for (let i = 1; i < 6; i++) {
          setTimeout(() => {
            if (this.playing) {
              this.animateKey = this.animates[i];
            }
          }, i * 1000);
        }
        setTimeout(() => {
          if (this.playing) {
            this.playing = false;
            this.animateKey = null;
          }
        }, 6000);
      }
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};