import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-83c5f9f6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "LogicalReasoning2DImgContainer"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.imgData, (row, rowindex) => {
    return _openBlock(), _createElementBlock("div", {
      class: "bothCenter",
      style: {
        "width": "100px"
      },
      key: `row_${rowindex}`
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (icon, iconIndex) => {
      return _openBlock(), _createElementBlock("img", {
        key: `icon_${iconIndex}`,
        src: $data.imgs[icon[0]][icon[1]],
        alt: "",
        style: {
          "width": "30px",
          "height": "30px"
        }
      }, null, 8, _hoisted_2);
    }), 128))]);
  }), 128))]);
}