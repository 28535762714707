export default {
    ansTypeMap: {//几个题一组
        FlashDiamond: 1,
        ImageTransform: 1,
        BlockRotate: 1,
        FillWall: 1,
        GroundPerspective: 1,
        CubeComplement: 1,
        E808A: 1,
        E808APlus: 1,
        E808B: 12,
        E808B2: 12,
        E808C: 12,
        E808D: 5,
        E808E: 1,
        E808EPlus: 1,
        LogicalReasoning: 1,
        Moss: 1,
        MossCopy: 1,
        Moss5: 1,
        MossMissing4: 1,
        MossMissing5: 1,
        QuickScale: 1,
        WhackAMole: 1,
        HarderMoss: 1,
        E808D2: 4,
        E808B3: 4,
        E808B4: 4,
        CodeMath: 1,
        FastMemory: 1,
        Seesaw: 1,
        Unfolding3D: 1,
        PriceCalculate: 1,
        FlashingRect: 1,
        RotateMatrix: 1,
        ColorRotateMatrix: 1,
        RandomDice: 1,
        SequenceBacktracking: 1,
        NeuralNetwork: 1,
        LogicalReasoning2D: 1,
        LogicalTransformer2D: 1,
        LogicalTransformerSector: 1,
        LogicalTransformerSectorPlus: 1,
    },
    ansModeMap: {//是否显示答题卡
        FlashDiamond: 0,
        ImageTransform: 0,
        BlockRotate: 0,
        FillWall: 0,
        GroundPerspective: 0,
        CubeComplement: 0,
        E808A: 1,
        E808APlus: 1,
        E808B: 1,
        E808B2: 1,
        E808C: 1,
        E808D: 1,
        E808E: 1,
        E808EPlus: 1,
        LogicalReasoning: 0,
        Moss: 0,
        MossCopy: 0,
        Moss5: 0,
        MossMissing4: 0,
        MossMissing5: 0,
        QuickScale: 1,
        WhackAMole: 0,
        HarderMoss: 0,
        E808D2: 1,
        E808B3: 1,
        E808B4: 1,
        CodeMath: 0,
        FastMemory: 0,
        Seesaw: 0,
        Unfolding3D: 0,
        PriceCalculate: 0,
        FlashingRect: 0,
        RotateMatrix: 0,
        ColorRotateMatrix: 0,
        RandomDice: 0,
        SequenceBacktracking: 0,
        NeuralNetwork: 0,
        LogicalReasoning2D:0,
        LogicalTransformer2D:0,
        LogicalTransformerSector:0,
        LogicalTransformerSectorPlus:0,
    },
};