import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6134997e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "QuestionReviewRenderPageContainer"
};
const _hoisted_2 = {
  style: {
    "color": "rgba(211,211,211,0.62)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ThreeRender = _resolveComponent("ThreeRender");
  const _component_GroundPerspectiveRender = _resolveComponent("GroundPerspectiveRender");
  const _component_CubeComplementRender = _resolveComponent("CubeComplementRender");
  const _component_FillWallRender = _resolveComponent("FillWallRender");
  const _component_BlockRotateRender = _resolveComponent("BlockRotateRender");
  const _component_RandomForestRender = _resolveComponent("RandomForestRender");
  const _component_LogicalReasoningRender = _resolveComponent("LogicalReasoningRender");
  const _component_LogicalReasoning2DRender = _resolveComponent("LogicalReasoning2DRender");
  const _component_Unfolding3DRender = _resolveComponent("Unfolding3DRender");
  const _component_FlashDiamondRender = _resolveComponent("FlashDiamondRender");
  const _component_PriceCalculateRender = _resolveComponent("PriceCalculateRender");
  const _component_BlackWhiteDotRender = _resolveComponent("BlackWhiteDotRender");
  const _component_ImageTransformRender = _resolveComponent("ImageTransformRender");
  const _component_WhackAMoleRender = _resolveComponent("WhackAMoleRender");
  const _component_E808APlusRender = _resolveComponent("E808APlusRender");
  const _component_MossRender = _resolveComponent("MossRender");
  const _component_E808EPlusRender = _resolveComponent("E808EPlusRender");
  const _component_QuickScaleRender = _resolveComponent("QuickScaleRender");
  const _component_CodeMathRender = _resolveComponent("CodeMathRender");
  const _component_FastMemoryRender = _resolveComponent("FastMemoryRender");
  const _component_SeesawRender = _resolveComponent("SeesawRender");
  const _component_LogicalTransformer2D = _resolveComponent("LogicalTransformer2D");
  const _component_LogicalTransformerSectorRender = _resolveComponent("LogicalTransformerSectorRender");
  const _component_E808BRender = _resolveComponent("E808BRender");
  const _component_E808DRender = _resolveComponent("E808DRender");
  const _component_E808B2Render = _resolveComponent("E808B2Render");
  const _component_E808CRender = _resolveComponent("E808CRender");
  const _component_E808D2Render = _resolveComponent("E808D2Render");
  const _component_E808B3Render = _resolveComponent("E808B3Render");
  const _component_FlashBoxRender = _resolveComponent("FlashBoxRender");
  const _component_DiceRender = _resolveComponent("DiceRender");
  const _component_NineGridRender = _resolveComponent("NineGridRender");
  const _component_CSGItem = _resolveComponent("CSGItem");
  const _component_StereoscopicSectionExamEditor = _resolveComponent("StereoscopicSectionExamEditor");
  const _component_MemoryNumbersRender = _resolveComponent("MemoryNumbersRender");
  const _component_GraphicalReasoningRender = _resolveComponent("GraphicalReasoningRender");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return $props.data ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.data.list[$data.reviewIndex].id), 1), $props.editMode ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [['BlockRotate', 'FillWall', 'CubeComplement', 'GroundPerspective', 'RandomForest'].includes($props.subject) ? (_openBlock(), _createBlock(_component_ThreeRender, {
    key: 0,
    ref: "threeRenderRef",
    subject: $props.subject,
    "init-runtime-params": $props.data.runtimeParams,
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams?.[$data.reviewIndex]
  }, null, 8, ["subject", "init-runtime-params", "data", "runtimeParams"])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$props.subject == 'GroundPerspective' ? (_openBlock(), _createBlock(_component_GroundPerspectiveRender, {
    key: 0,
    review: $props.data.answers[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams,
    reviewMode: true
  }, null, 8, ["review", "data", "runtimeParams"])) : _createCommentVNode("", true), $props.subject == 'CubeComplement' ? (_openBlock(), _createBlock(_component_CubeComplementRender, {
    key: 1,
    review: $props.data.answers[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams,
    reviewMode: true
  }, null, 8, ["review", "data", "runtimeParams"])) : _createCommentVNode("", true), $props.subject == 'FillWall' ? (_openBlock(), _createBlock(_component_FillWallRender, {
    key: 2,
    review: $props.data.answers[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams,
    reviewMode: true
  }, null, 8, ["review", "data", "runtimeParams"])) : _createCommentVNode("", true), $props.subject == 'BlockRotate' ? (_openBlock(), _createBlock(_component_BlockRotateRender, {
    key: 3,
    review: $props.data.answers[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams,
    reviewMode: true
  }, null, 8, ["review", "data", "runtimeParams"])) : _createCommentVNode("", true), $props.subject == 'RandomForest' ? (_openBlock(), _createBlock(_component_RandomForestRender, {
    key: 4,
    review: $props.data.answers[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams,
    reviewMode: true
  }, null, 8, ["review", "data", "runtimeParams"])) : _createCommentVNode("", true)], 64)), $props.subject == 'LogicalReasoning' ? (_openBlock(), _createBlock(_component_LogicalReasoningRender, {
    key: 2,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'LogicalReasoning2D' ? (_openBlock(), _createBlock(_component_LogicalReasoning2DRender, {
    key: 3,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'Unfolding3D' ? (_openBlock(), _createBlock(_component_Unfolding3DRender, {
    key: 4,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'FlashDiamond' ? (_openBlock(), _createBlock(_component_FlashDiamondRender, {
    key: 5,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'PriceCalculate' ? (_openBlock(), _createBlock(_component_PriceCalculateRender, {
    key: 6,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'BlackWhiteDot' ? (_openBlock(), _createBlock(_component_BlackWhiteDotRender, {
    key: 7,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'ImageTransform' ? (_openBlock(), _createBlock(_component_ImageTransformRender, {
    key: 8,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'WhackAMole' ? (_openBlock(), _createBlock(_component_WhackAMoleRender, {
    key: 9,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808APlus' || $props.subject == 'E808A' ? (_openBlock(), _createBlock(_component_E808APlusRender, {
    key: 10,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'Moss' || $props.subject == 'HarderMoss' || $props.subject == 'MossCopy' || $props.subject == 'Moss5' || $props.subject == 'MossMissing4' || $props.subject == 'MossMissing5' ? (_openBlock(), _createBlock(_component_MossRender, {
    key: 11,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808EPlus' || $props.subject == 'E808E' ? (_openBlock(), _createBlock(_component_E808EPlusRender, {
    key: 12,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'QuickScale' ? (_openBlock(), _createBlock(_component_QuickScaleRender, {
    key: 13,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'CodeMath' ? (_openBlock(), _createBlock(_component_CodeMathRender, {
    key: 14,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'FastMemory' ? (_openBlock(), _createBlock(_component_FastMemoryRender, {
    key: 15,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'Seesaw' ? (_openBlock(), _createBlock(_component_SeesawRender, {
    key: 16,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'LogicalTransformer2D' ? (_openBlock(), _createBlock(_component_LogicalTransformer2D, {
    key: 17,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    question: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "question"])) : _createCommentVNode("", true), $props.subject == 'LogicalTransformerSector' ? (_openBlock(), _createBlock(_component_LogicalTransformerSectorRender, {
    key: 18,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    question: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "question"])) : _createCommentVNode("", true), $props.subject == 'LogicalTransformerSectorPlus' ? (_openBlock(), _createBlock(_component_LogicalTransformerSectorRender, {
    key: 19,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    question: $props.data.list[$data.reviewIndex],
    plus: ""
  }, null, 8, ["review", "question"])) : _createCommentVNode("", true), $props.subject == 'E808B' ? (_openBlock(), _createBlock(_component_E808BRender, {
    key: 20,
    review: $props.data.answers,
    reviewMode: true,
    data: $props.data.list.slice($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize)
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808D' ? (_openBlock(), _createBlock(_component_E808DRender, {
    key: 21,
    review: $props.data.answers,
    reviewMode: true,
    data: $props.data.list.slice($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize)
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808B2' ? (_openBlock(), _createBlock(_component_E808B2Render, {
    key: 22,
    review: $props.data.answers,
    reviewMode: true,
    data: $props.data.list.slice($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize)
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808C' ? (_openBlock(), _createBlock(_component_E808CRender, {
    key: 23,
    review: $props.data.answers,
    reviewMode: true,
    data: $props.data.list.slice($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize)
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808D2' ? (_openBlock(), _createBlock(_component_E808D2Render, {
    key: 24,
    review: $props.data.answers,
    reviewMode: true,
    data: $props.data.list.slice($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize)
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'E808B3' || $props.subject == 'E808B4' ? (_openBlock(), _createBlock(_component_E808B3Render, {
    key: 25,
    review: $props.data.answers,
    reviewMode: true,
    data: $props.data.list.slice($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize)
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'FlashingRect' ? (_openBlock(), _createBlock(_component_FlashBoxRender, {
    key: 26,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'RandomDice' && $props.editMode ? (_openBlock(), _createBlock(_component_DiceRender, {
    key: 27,
    ref: "diceRenderRef",
    editMode: "",
    review: $props.data.answers[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex],
    "init-runtime-params": $props.data.runtimeParams,
    runtimeParams: $props.data.runtimeParams?.[$data.reviewIndex]
  }, null, 8, ["review", "data", "init-runtime-params", "runtimeParams"])) : $props.subject == 'RandomDice' ? (_openBlock(), _createBlock(_component_DiceRender, {
    key: 28,
    ref: "diceRenderRef",
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex],
    runtimeParams: $props.data.runtimeParams
  }, null, 8, ["review", "data", "runtimeParams"])) : _createCommentVNode("", true), $props.subject == 'RotateMatrix' ? (_openBlock(), _createBlock(_component_NineGridRender, {
    key: 29,
    subject: "NineGrid",
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'ColorRotateMatrix' ? (_openBlock(), _createBlock(_component_NineGridRender, {
    key: 30,
    subject: "NineGridColor",
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'StereoscopicSection' ? (_openBlock(), _createBlock(_component_CSGItem, {
    key: 31
  })) : _createCommentVNode("", true), $props.subject && $props.subject == 'StereoscopicSectionExam' ? (_openBlock(), _createBlock(_component_StereoscopicSectionExamEditor, {
    key: 32,
    subject: "NineGridColor",
    ref: "StereoscopicSectionExamEditor",
    editMode: $props.editMode,
    review: $props.data.answers[$data.reviewIndex],
    "init-runtime-params": $props.data.runtimeParams,
    runtimeParams: $props.data.runtimeParams?.[$data.reviewIndex],
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["editMode", "review", "init-runtime-params", "runtimeParams", "data"])) : _createCommentVNode("", true), $props.subject == 'SequenceBacktracking' ? (_openBlock(), _createBlock(_component_MemoryNumbersRender, {
    key: 33,
    review: $props.data.answers[$data.reviewIndex],
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex]
  }, null, 8, ["review", "data"])) : _createCommentVNode("", true), $props.subject == 'NeuralNetwork' ? (_openBlock(), _createBlock(_component_GraphicalReasoningRender, {
    key: 34,
    ref: "neuralNetworkRef",
    review: $props.data.answers[$data.reviewIndex],
    editMode: $props.editMode,
    reviewMode: true,
    data: $props.data.list[$data.reviewIndex],
    "init-runtime-params": $props.data.runtimeParams,
    runtimeParams: $props.data.runtimeParams?.[$data.reviewIndex]
  }, null, 8, ["review", "editMode", "data", "init-runtime-params", "runtimeParams"])) : _createCommentVNode("", true), _createVNode(_component_el_row, {
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 8,
      class: "leftCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        style: {
          "width": "100px"
        },
        disabled: $data.reviewIndex < 1,
        onClick: _cache[0] || (_cache[0] = $event => $data.reviewIndex--)
      }, {
        default: _withCtx(() => [_createTextVNode("上一" + _toDisplayString($props.groupSize > 1 ? '组' : '题'), 1)]),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      class: "bothCenter"
    }, {
      default: _withCtx(() => [$props.editMode ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        type: "success",
        onClick: _cache[1] || (_cache[1] = $event => $options.reGenerate($data.reviewIndex * $props.groupSize, ($data.reviewIndex + 1) * $props.groupSize))
      }, {
        default: _withCtx(() => [_createTextVNode("重新生成 ")]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      class: "rightCenter"
    }, {
      default: _withCtx(() => [($data.reviewIndex + 1) * $props.groupSize >= $props.data.total ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [$props.editMode && ['StereoscopicSectionExam', 'BlockRotate', 'FillWall', 'CubeComplement', 'GroundPerspective', 'RandomForest', 'RandomDice'].includes($props.subject) ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        type: "primary",
        onClick: $options.handleSave
      }, {
        default: _withCtx(() => [_createTextVNode(" 完成保存 ")]),
        _: 1
      }, 8, ["onClick"])) : $props.editMode ? (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        type: "primary",
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.go(-1))
      }, {
        default: _withCtx(() => [_createTextVNode(" 结束编辑 ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_el_button, {
        key: 2,
        type: "primary",
        onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.replace('/'))
      }, {
        default: _withCtx(() => [_createTextVNode("结束回看 ")]),
        _: 1
      }))], 64)) : (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        type: "primary",
        style: {
          "width": "100px"
        },
        onClick: $options.handleNext
      }, {
        default: _withCtx(() => [_createTextVNode("下一" + _toDisplayString($props.groupSize > 1 ? '组' : '题'), 1)]),
        _: 1
      }, 8, ["onClick"]))]),
      _: 1
    })]),
    _: 1
  })])) : _createCommentVNode("", true);
}