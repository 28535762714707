export default {
  name: "LogicalTransformer2DImg",
  components: {},
  props: {
    grid: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};