export default {
  name: "SeesawLoad",
  props: {
    data: {
      type: String,
      default: 'A'
    }
  },
  data() {
    return {
      mapping: {
        'A': 'https://cdn.waityou.online/17c1fdb6-0fa1-6429-6ec3-339a59f1ff55.png',
        'B': 'https://cdn.waityou.online/c30f5d99-617e-8ac5-3a0a-d73a369ea8a6.png',
        'C': 'https://cdn.waityou.online/e8fb019f-06d3-342d-71ec-c4190efdc51d.png',
        'D': 'https://cdn.waityou.online/f86f60ae-4688-3479-521d-3d43cfe63cf1.png',
        'E': 'https://cdn.waityou.online/be7366b8-5720-9e4d-56fb-66fe402a2573.png',
        'F': 'https://cdn.waityou.online/4bd31062-9bed-3144-c425-86d532138572.png',
        'G': 'https://cdn.waityou.online/9a8a427c-0463-7245-ef7b-c4c4b392c2ed.png',
        'H': 'https://cdn.waityou.online/e8fe7d5f-c459-82fd-71d7-5fc1c0c4313a.png'
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};