import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.some.js";
import OrderedAnswerButtonGroup from "@/components/train/OrderedAnswerButtonGroup";
export default {
  name: "Unfolding3DRender",
  components: {
    OrderedAnswerButtonGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    idx: {
      type: Number,
      default: 0
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: null
    };
  },
  methods: {
    maxLetterToOptions(chars) {
      if (!Array.isArray(chars) || chars.some(char => typeof char !== 'string' || char.length !== 1 || char < 'A' || char > 'Z')) {
        throw new Error('Input must be an array of single uppercase letters.');
      }
      // Find the character with the highest ASCII code
      let maxChar = 'A';
      chars.forEach(char => {
        if (char > maxChar) {
          maxChar = char;
        }
      });
      // Generate the array from 'A' to the maxChar
      let resultArray = [];
      for (let i = 'A'.charCodeAt(0); i <= maxChar.charCodeAt(0); i++) {
        resultArray.push(String.fromCharCode(i));
      }
      // Ensure the array length is even
      if (resultArray.length % 2 !== 0) {
        let nextCharCode = maxChar.charCodeAt(0) + 1;
        // If the next character is beyond 'Z', loop back to 'A'
        if (nextCharCode > 'Z'.charCodeAt(0)) {
          nextCharCode = 'A'.charCodeAt(0);
        }
        resultArray.push(String.fromCharCode(nextCharCode));
      }
      return resultArray;
    },
    onChoosed(l) {
      console.log('onchoosed', l);
      this.answer = l && l.length > 0 ? l.join(',') : '';
      this.$emit('answer', this.answer);
    },
    init() {
      this.answer = null;
    }
  },
  mounted() {
    this.init();
  }
};