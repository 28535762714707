import LogicalTransformer2DImg from "@/components/subject/LogicalTransformer2D/LogicalTransformer2DImg.vue";
import AnswerButtonGroup from "@/components/train/AnswerButtonGroup.vue";
export default {
  name: "LogicalTransformer2D",
  components: {
    AnswerButtonGroup,
    LogicalTransformer2DImg
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      OPTIONS: ['A', 'B', 'C', 'D'],
      transformers: {
        MOVE_ROW_1_DOWN_1: "第 1 行向下移动 1 格",
        MOVE_ROW_1_DOWN_2: "第 1 行向下移动 2 格",
        MOVE_ROW_1_DOWN_3: "第 1 行向下移动 3 格",
        MOVE_ROW_2_DOWN_1: "第 2 行向下移动 1 格",
        MOVE_ROW_2_DOWN_2: "第 2 行向下移动 2 格",
        MOVE_ROW_3_DOWN_1: "第 3 行向下移动 1 格",
        MOVE_COLUMN_1_RIGHT_1: "第 1 列向右移动 1 格",
        MOVE_COLUMN_1_RIGHT_2: "第 1 列向右移动 2 格",
        MOVE_COLUMN_1_RIGHT_3: "第 1 列向右移动 3 格",
        MOVE_COLUMN_2_RIGHT_1: "第 2 列向右移动 1 格",
        MOVE_COLUMN_2_RIGHT_2: "第 2 列向右移动 2 格",
        MOVE_COLUMN_3_RIGHT_1: "第 3 列向右移动 1 格",
        EXCHANGE_COLUMNS_1_2: "第 1、2 列交换",
        EXCHANGE_COLUMNS_1_3: "第 1、3 列交换",
        EXCHANGE_COLUMNS_1_4: "第 1、4 列交换",
        EXCHANGE_COLUMNS_2_3: "第 2、3 列交换",
        EXCHANGE_COLUMNS_2_4: "第 2、4 列交换",
        EXCHANGE_COLUMNS_3_4: "第 3、4 列交换",
        EXCHANGE_ROWS_1_2: "第 1、2 行交换",
        EXCHANGE_ROWS_1_3: "第 1、3 行交换",
        EXCHANGE_ROWS_1_4: "第 1、4 行交换",
        EXCHANGE_ROWS_2_3: "第 2、3 行交换",
        EXCHANGE_ROWS_2_4: "第 2、4 行交换",
        EXCHANGE_ROWS_3_4: "第 3、4 行交换",
        ROTATE_CLOCKWISE_90: "整体顺时针旋转 90 度",
        ROTATE_CLOCKWISE_180: "整体顺时针旋转 180 度",
        ROTATE_CLOCKWISE_270: "整体顺时针旋转 270 度",
        FLIP_HORIZONTAL: "整体左右翻转",
        FLIP_VERTICAL: "整体上下翻转",
        INVERSE_COLORS: "整体黑白互换"
      }
    };
  },
  methods: {
    init() {},
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l[0] : null);
    }
  },
  mounted() {
    this.init();
  }
};