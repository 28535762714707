import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-43da43ea"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container gap-4"
};
const _hoisted_2 = {
  class: "content flex-1 bg-gray-50 z-10 p-4 border-box border rounded-2xl"
};
const _hoisted_3 = {
  class: "absolute p-4 flex items-center gap-4"
};
const _hoisted_4 = {
  key: 0,
  class: "flex items-center gap-1"
};
const _hoisted_5 = {
  key: 1,
  class: "flex items-center gap-1"
};
const _hoisted_6 = {
  key: 0,
  class: "absolute p-4 right-4"
};
const _hoisted_7 = {
  class: "render-container gap-4"
};
const _hoisted_8 = {
  class: "flex flex-wrap gap-4 w-[400px] items-center justify-center"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "w-[90px] h-[90px] rounded-xl overflow-hidden"
};
const _hoisted_11 = ["src"];
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { onMounted, ref, watch } from "vue";
import { models } from "@/models.js";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import uploader from "@/utils/uploader";
import { ElMessage } from "element-plus";
export default {
  __name: 'CSGItem',
  props: {
    previewImages: {
      type: Object,
      default: () => {
        return {};
      }
    },
    localIndex: {
      type: Number,
      default: 0
    },
    isSave: {
      type: Boolean,
      default: true
    },
    isDesc: {
      type: Boolean,
      default: false
    },
    shotMode: {
      //照片模式
      type: Boolean,
      default: false
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: 200,
          height: 200
        };
      }
    }
  },
  emits: ["close"],
  setup(__props, {
    emit: __emit
  }) {
    const rendererRef = ref(null);
    const loading = ref(false);
    const mode = ref("translate");
    const selectedIndex = ref(0);
    const count = ref(1);
    const index = ref(0);
    let transformControls;
    let camera, scene, renderer;
    let planes;
    let planeObjects = [];
    let planeHelpers = [];
    const planeGeom = new THREE.PlaneGeometry(4, 4);
    let planeGroup = new THREE.Group();
    const material = new THREE.MeshStandardMaterial({
      color: 0xffc107,
      metalness: 0.1,
      roughness: 0.75,
      clipShadows: true,
      shadowSide: THREE.DoubleSide
    });
    const clippedColorFront = new THREE.Mesh();
    clippedColorFront.material = material;
    const mergeGeometries = model => {
      const geometries = [];
      model.traverse(child => {
        if (child instanceof THREE.Mesh) {
          const geometry = child.geometry.clone();
          geometry.applyMatrix4(child.matrixWorld);
          geometries.push(geometry);
        }
      });
      return BufferGeometryUtils.mergeGeometries(geometries);
    };
    const normalizeGeometry = (geometry, bottom) => {
      if (!geometry.boundingBox) {
        geometry.computeBoundingBox();
      }
      const bbox = geometry.boundingBox;
      if (!bbox) return geometry;
      const size = bbox.getSize(new THREE.Vector3());
      const center = bbox.getCenter(new THREE.Vector3());
      const maxDimension = Math.max(size.x, size.y, size.z);
      const scale = 1 / maxDimension;
      geometry.scale(scale, scale, scale);
      center.multiplyScalar(scale);
      geometry.translate(-center.x, bottom ? bbox.min.y : -center.y, -center.z);
      return geometry;
    };
    const shotNow = () => {
      //在此处捕捉图像
      ElMessage.info("请稍等...");
      transformControls.visible = false;
      planeHelpers.forEach(x => {
        x.visible = false;
      });
      if (loading.value) return;
      // 先确保渲染器渲染的是正确的内容
      renderer.render(scene, camera);
      renderer.domElement.toBlob(blob => {
        uploader.uploadBlob(blob, "png", url => {
          ElMessage.success("图像捕捉成功");
          emit("close", url);
        });
      });
    };
    function createPlaneStencilGroup(geometry, plane, renderOrder) {
      const group = new THREE.Group();
      const baseMat = new THREE.MeshBasicMaterial();
      baseMat.depthWrite = false;
      baseMat.depthTest = false;
      baseMat.colorWrite = false;
      baseMat.stencilWrite = true;
      baseMat.stencilFunc = THREE.AlwaysStencilFunc;

      // back faces
      const mat0 = baseMat.clone();
      mat0.side = THREE.BackSide;
      mat0.clippingPlanes = [plane];
      mat0.stencilFail = THREE.IncrementWrapStencilOp;
      mat0.stencilZFail = THREE.IncrementWrapStencilOp;
      mat0.stencilZPass = THREE.IncrementWrapStencilOp;
      const mesh0 = new THREE.Mesh(geometry, mat0);
      mesh0.renderOrder = renderOrder;
      group.add(mesh0);

      // front faces
      const mat1 = baseMat.clone();
      mat1.side = THREE.FrontSide;
      mat1.clippingPlanes = [plane];
      mat1.stencilFail = THREE.DecrementWrapStencilOp;
      mat1.stencilZFail = THREE.DecrementWrapStencilOp;
      mat1.stencilZPass = THREE.DecrementWrapStencilOp;
      const mesh1 = new THREE.Mesh(geometry, mat1);
      mesh1.renderOrder = renderOrder;
      group.add(mesh1);
      return group;
    }
    function init() {
      const container = rendererRef.value;
      const width = container.clientWidth;
      const height = container.clientHeight;
      const clearColor = 0xf0f0ff;
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xffffff);
      scene.fog = new THREE.Fog(clearColor, 10, 15);
      scene.background = new THREE.Color(clearColor);
      camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 10);
      camera.position.set(1, 1.5, -1.5);
      camera.lookAt(0, 0, 0);

      // 环境光
      const ambientLight = new THREE.AmbientLight(0x606060, 20); // 软白光
      scene.add(ambientLight);

      // 平行光
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(10, 10, 5);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 1024; // default
      directionalLight.shadow.mapSize.height = 1024; // default
      directionalLight.shadow.camera.near = 0.5; // default
      directionalLight.shadow.camera.far = 500; // default
      scene.add(directionalLight);

      // 渲染器
      renderer = new THREE.WebGLRenderer({
        antialias: true,
        stencil: true
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      // renderer.setClearColor(0x263238);
      renderer.setAnimationLoop(animate);
      renderer.shadowMap.enabled = true;
      renderer.localClippingEnabled = true;
      rendererRef.value.appendChild(renderer.domElement);

      // 变换控制器
      transformControls = new TransformControls(camera, renderer.domElement);
      transformControls.addEventListener("dragging-changed", e => {
        controls.enabled = !e.value;
      });
      transformControls.addEventListener("objectChange", () => {
        updatePlane();
      });
      scene.add(transformControls);

      // 控制器
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      controls.enableZoom = true;
      controls.minDistance = 2;
      controls.maxDistance = 20;
      const ground = new THREE.Mesh(new THREE.PlaneGeometry(100, 100), new THREE.MeshStandardMaterial({
        color: 0xf0f0ff
      }));
      ground.rotation.x = -Math.PI / 2; // rotates X/Y to X/Z
      ground.position.y = -0.5;
      ground.receiveShadow = true;
      scene.add(ground);
      clippedColorFront.castShadow = true;
      scene.add(clippedColorFront);
      loadModel();
    }
    function setupPlanes(geometry) {
      planes = new Array(count.value).fill().map((_, i) => {
        return new THREE.Plane(new THREE.Vector3((Math.random() - 0.5) * Math.PI * 2, (Math.random() - 0.5) * Math.PI * 2, (Math.random() - 0.5) * Math.PI * 2), 0);
      });
      planeHelpers.forEach(ph => {
        scene.remove(ph);
      });
      planeHelpers = planes.map(p => new THREE.PlaneHelper(p, 2, 0xffffff));
      planeHelpers.forEach(ph => {
        // ph.visible = false;
        scene.add(ph);
      });
      material.clippingPlanes = planes;
      planeObjects = [];
      scene.remove(planeGroup);
      planeGroup = new THREE.Group();
      clippedColorFront.renderOrder = planes.length * 2;
      planes.forEach((plane, i) => {
        const stencilGroup = createPlaneStencilGroup(geometry, plane, i + 1);

        // plane is clipped by the other clipping planes
        const planeMat = new THREE.MeshStandardMaterial({
          color: props.shotMode ? 0xffc107 : 0xe91e63,
          metalness: 0.1,
          roughness: 0.75,
          clippingPlanes: planes.filter(p => p !== plane),
          stencilWrite: true,
          stencilRef: 0,
          stencilFunc: THREE.NotEqualStencilFunc,
          stencilFail: THREE.ReplaceStencilOp,
          stencilZFail: THREE.ReplaceStencilOp,
          stencilZPass: THREE.ReplaceStencilOp
        });
        const po = new THREE.Mesh(planeGeom, planeMat);
        po.onAfterRender = function (renderer) {
          renderer.clearStencil();
        };
        po.renderOrder = i + 1.1;
        stencilGroup.add(po);
        planeObjects.push(po);
        planeGroup.add(stencilGroup);
        plane.coplanarPoint(po.position);
        po.lookAt(po.position.x - plane.normal.x, po.position.y - plane.normal.y, po.position.z - plane.normal.z);
      });
      scene.add(planeGroup);
      transformControls.attach(planeObjects[index.value]);
    }
    const updatePlane = () => {
      const plane = planes[index.value];
      const normal = new THREE.Vector3();
      const point = new THREE.Vector3();
      const po = planeObjects[index.value];
      po.updateMatrixWorld();
      po.getWorldDirection(normal);
      point.setFromMatrixPosition(po.matrixWorld);
      plane.setFromNormalAndCoplanarPoint(normal.negate(), point);
      planeGroup.children[index.value].children.forEach(child => {
        if (child === po) return;
        child.material.clippingPlanes = [plane];
      });
      planeHelpers[index.value].plane = plane;
    };
    function animate() {
      renderer.render(scene, camera);
    }
    const emit = __emit;
    const props = __props;
    const loadModel = () => {
      const item = models[selectedIndex.value];
      if (!item) return;
      const loader = new OBJLoader();
      loading.value = true;
      loader.load(`https://cdn.zhixuein.com/models/new/${item}.obj`, object => {
        loading.value = false;
        const geometry = normalizeGeometry(mergeGeometries(object));
        clippedColorFront.geometry = geometry;
        setupPlanes(geometry);
      });
    };
    watch(count, () => {
      setupPlanes(clippedColorFront.geometry);
    });
    watch(mode, val => {
      transformControls.setMode(val);
    });
    watch(selectedIndex, () => {
      loadModel();
    });
    watch(index, val => {
      const po = planeObjects[val];
      if (!po) return;
      transformControls.attach(po);
    });
    const handleSelect = index => {
      if (loading.value) return;
      selectedIndex.value = index;
    };
    onMounted(() => {
      setTimeout(init, 200);
    });
    return (_ctx, _cache) => {
      const _component_Rank = _resolveComponent("Rank");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_Refresh = _resolveComponent("Refresh");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_Camera = _resolveComponent("Camera");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_radio_group, {
        modelValue: mode.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => mode.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio_button, {
          label: "translate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Rank)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_radio_button, {
          label: "rotate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Refresh)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), __props.shotMode ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createTextVNode(" 刀数: "), _createVNode(_component_el_input_number, {
        modelValue: count.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => count.value = $event)
      }, null, 8, ["modelValue"])])) : _createCommentVNode("", true), __props.shotMode ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createTextVNode(" 控制: "), _createVNode(_component_el_select, {
        modelValue: index.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => index.value = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(count.value, i => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: `ctr_${i}`,
            label: i,
            value: i - 1
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])])) : _createCommentVNode("", true)]), __props.shotMode ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_radio_group, {
        onClick: shotNow
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio_button, {
          label: "shot"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Camera)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("div", {
        ref_key: "rendererRef",
        ref: rendererRef,
        class: "flex-1 border aspect-square rounded-xl overflow-hidden bg-white"
      }, null, 512), [[_directive_loading, loading.value]])])]), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(models), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: item,
          class: _normalizeClass(["render-item", [selectedIndex.value === index && 'selected', loading.value && 'loading', 'w-[80px] h-[80px]']]),
          onClick: $event => handleSelect(index)
        }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
          src: `https://cdn.zhixuein.com/models/new/${item}.png`,
          class: "w-full h-full object-cover"
        }, null, 8, _hoisted_11)])], 10, _hoisted_9);
      }), 128))])]);
    };
  }
};