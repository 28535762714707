const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/user/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/m/accountLogin",
    logout: "/api/user/logout?channel=0",

    getOssKeySecret: "/manager/oss/getOssKeySecret",
    updateSystemConfig: '/api/manager/config/update',
    getSystemConfig: '/manager/system/setting/getSettings',
    updateImageValue: '/api/manager/config/updateImageValue',
    getEnumOptionList: '/api/manager/enum/list4select/{0}',
    startPractice: '/user/exam/startPractice?subject={0}',
    queryCurrentQuestion: '/user/exam/queryCurrentQuestion?examId={0}&history=true',
    submitAnswer: '/user/exam/submitAnswer',
    queryHistoryExamResult: '/user/exam/queryHistoryExamResult?examId={0}',
    batchSubmitAnswer: '/user/exam/batchSubmitAnswer',
    getUserGroupList: '/manager/user/group/list4select',
    deleteUser: '/manager/deleteUserById?id={0}',
    deleteGroup: '/manager/user/group/deleteGroup?id={0}&keepUser={1}',
    getLogicalGroups: '/manager/logicalreasoning/list4select',
    getLogical2dGroups: '/manager/logicalreasoning/2d/list4select',
    getUnfolding3DQuestionGroups: '/manager/unfolding3d/list4select',
    getLogicalQuestionTypeMap: '/manager/logicalreasoning/questionTypes',
    getLogicalQuestionByGroupId: '/manager/logicalreasoning/list?groupId={0}&page=0&pageSize=500',
    getLogical2dQuestionByGroupId: '/manager/logicalreasoning/2d/list?groupId={0}&page=0&pageSize=500',
    getUnfolding3DQuestionByGroupId: '/manager/unfolding3d/list?groupId={0}&page=0&pageSize=500',
    deleteLogicalQuestion: '/manager/logicalreasoning/delete?id={0}',
    deleteLogical2dQuestion: '/manager/logicalreasoning/2d/delete?id={0}',
    deleteUnfolding3dQuestion: '/manager/unfolding3d/delete?id={0}',
    deleteLogicalGroup: '/manager/logicalreasoning/deleteGroup?groupId={0}',
    deleteLogical2dGroup: '/manager/logicalreasoning/2d/deleteGroup?groupId={0}',
    deleteUnfolding3dGroup: '/manager/unfolding3d/deleteGroup?groupId={0}',
    getQuestionTplGroupList: '/manager/tpl/list4select',
    deleteTplGroup: '/manager/tpl/delete?groupId={0}',
    markTplGroupAsHistory: '/manager/tpl/markAsHistory?groupId={0}',
    getQuestionTplInfo: '/manager/tpl/question/info?tplId={0}',
    previewTplQuestion: '/manager/tpl/question/previewTplQuestion?tplId={0}',
    reGenerateQuestionTpl: '/manager/tpl/question/reGenerateQuestionTpl',
    updateTplQuestionId: '/manager/tpl/question/updateTplQuestionId',
    updateTplRuntimeParams: '/manager/tpl/question/updateRuntimeParams',
    getTeachPlanInfo: '/manager/class/plan/info?planId={0}',
    addTpl2ClassPlan: '/manager/class/plan/addTpl',
    deleteTplFromPlan: '/manager/class/plan/deleteTpl',
    getClassRoomInfo: '/manager/class/room/info?roomId={0}',
    enableClassTpl: '/manager/class/room/enableClassTpl',
    queryClassRoom: '/user/class/room/queryClassRoom',
    getActiveTplList: '/user/class/room/getActiveTplList?roomId={0}',
    startTplWork: '/user/class/room/startTplWork',
    startTplWorkById: '/user/class/room/startTplWorkById?id={0}',
    getClassAnswerStatistic: '/manager/class/room/statistic',
    getSeesawGroups: '/manager/seesaw/list4select',
    getSeesawByGroupId: '/manager/seesaw/list?groupId={0}&page=0&pageSize=500',
    deleteSeesawQuestion: '/manager/seesaw/delete?id={0}',
    deleteSeesawGroup: '/manager/seesaw/deleteGroup?groupId={0}',
    getPriceCalculateGroups: '/manager/price/calculate/list4select',
    getPriceCalculateByGroupId: '/manager/price/calculate/list?groupId={0}&page=0&pageSize=500',
    deletePriceCalculateQuestion: '/manager/price/calculate/delete?id={0}',
    deletePriceCalculateGroup: '/manager/price/calculate/deleteGroup?groupId={0}',
    getUserMenu: '/user/info/menu',
    getDifficultySettings: '/manager/difficulty/setting/getSettings',
    updateSubjectSetting: '/manager/difficulty/setting/update',
    updateSystemSetting: '/manager/system/setting/update',
    updateRandomPractice: '/manager/tpl/question/updateRandomPractice?id={0}',
    getServerTime: '/open/serverTime',


    queryForm: {
        1: '/manager/class/plan/getQueryForm'
    },
    dialogs: {
        1: '/manager/practice/chance/getPracticeEditForm?userId={0}',
        2: '/manager/user/group/getUserGroupEditDialog?userId={0}',
        3: '/manager/getUserCreateForm?groupId={0}',
        4: '/manager/getUserCreateForm',
        5: '/manager/user/group/getCreateGroupDialog',
        6: '/manager/logicalreasoning/getCreateGroupDialog',
        7: '/manager/logicalreasoning/getUploadDialog',
        8: '/manager/tpl/getCreateGroupDialog',
        9: '/manager/tpl/question/getCreateDialog',
        10: '/manager/tpl/question/getEditDialog?id={0}',
        11: '/manager/class/plan/getCreateDialog',
        12: '/manager/class/plan/getEditDialog?id={0}',
        13: '/manager/class/room/getCreateDialog',
        14: '/manager/class/room/getOffClassDialog',
        15: '/manager/seesaw/getCreateGroupDialog',
        16: '/manager/seesaw/getUploadDialog',
        17: '/manager/getUserUploadForm?groupId={0}',
        18: '/manager/getUserExamReviewForm?userId={0}',
        19: '/manager/price/calculate/getCreateGroupDialog',
        20: '/manager/price/calculate/getUploadDialog',
        21: '/manager/unfolding3d/getCreateGroupDialog',
        22: '/manager/unfolding3d/getUploadDialog',
        23: '/manager/logicalreasoning/2d/getCreateGroupDialog',
    },
    simpleBtn: {
        1: {
            dialogId: 1,
            submitUrl: '/manager/practice/chance/updatePracticeChance',
        },
        2: {
            dialogId: 2,
            submitUrl: '/manager/user/group/updateUserGroupConfig',
        },
        3: {
            dialogId: 3,
            submitUrl: '/manager/createUser',
        },
        4: {
            dialogId: 5,
            submitUrl: '/manager/user/group/createGroup',
        },
        5: {
            dialogId: 6,
            submitUrl: '/manager/logicalreasoning/addGroup',
        },
        6: {
            dialogId: 7,
            submitUrl: '/manager/logicalreasoning/zipUpload',
        },
        7: {
            dialogId: 8,
            submitUrl: '/manager/tpl/createGroup',
        },
        8: {
            dialogId: 9,
            submitUrl: '/manager/tpl/question/create',
        },
        9: {
            dialogId: 13,
            submitUrl: '/manager/class/room/create',
        },
        10: {
            dialogId: 14,
            submitUrl: '/manager/class/room/offClass',
        },
        11: {
            dialogId: 15,
            submitUrl: '/manager/seesaw/addGroup',
        },
        12: {
            dialogId: 16,
            submitUrl: '/manager/seesaw/importFromXlsx',
        },
        13: {
            dialogId: 17,
            submitUrl: '/manager/importUserFromXlsx',
        },
        14: {
            dialogId: 18,
            submitUrl: '/manager/updateUserExamReviewForm',
        },
        15: {
            dialogId: 19,
            submitUrl: '/manager/price/calculate/addGroup',
        },
        16: {
            dialogId: 20,
            submitUrl: '/manager/price/calculate/importFromXlsx',
        },
        17: {
            dialogId: 21,
            submitUrl: '/manager/unfolding3d/addGroup',
        },
        18: {
            dialogId: 22,
            submitUrl: '/manager/unfolding3d/zipUpload',
        },
        19: {
            dialogId: 23,
            submitUrl: '/manager/logicalreasoning/2d/addGroup',
        },
    },
    table: {
        1: {
            list: '/manager/user/group/userList',
        },
        2: {
            list: '/manager/tpl/question/list',
            updateDialog: 10,
            updateKey: 'id',
            update: '/manager/tpl/question/update',
            delete: '/manager/tpl/question/delete',
            deleteKey: 'id',
        },
        3: {
            queryForm: 1,
            list: '/manager/class/plan/list',
            createDialog: 11,
            create: '/manager/class/plan/create',
            updateDialog: 12,
            updateKey: 'id',
            update: '/manager/class/plan/update',
            delete: '/manager/class/plan/delete',
            deleteKey: 'id',
        },
        4: {
            list: '/manager/class/room/list',
            delete: '/manager/class/room/delete',
            deleteKey: 'id',
        },
        5: {
            list: '/manager/class/room/userLoginTable',
        },
        6: {
            list: '/user/class/room/homework',
        },
        7: {
            list: '/user/answer/history/list',
        },
        8: {
            list: '/manager/class/room/answerTable',
        },
        9: {
            list: '/user/class/room/classwork',
        },
    }

}

export default base;
