export default {
  name: "LogicalTransformerSectorImg",
  components: {},
  props: {
    // arcs 数组，长度为 4，元素取值为 0/1/2/3
    arcs: {
      type: Array,
      default: () => [0, 1, 2, 3] // 默认依次映射为 [红, 黄, 绿, 蓝]
    }
  },
  computed: {
    circleStyle() {
      // 定义颜色映射表
      const colorMap = {
        0: 'red',
        1: 'yellow',
        2: 'green',
        3: 'blue'
      };

      // 取出 arcs 数组中四个数字对应的颜色
      const [arc1, arc2, arc3, arc4] = this.arcs;
      const color1 = colorMap[arc1] || 'transparent';
      const color2 = colorMap[arc2] || 'transparent';
      const color3 = colorMap[arc3] || 'transparent';
      const color4 = colorMap[arc4] || 'transparent';
      return {
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        border: '2px solid #000',
        // 使用 conic-gradient 来分别设置四个扇区的颜色
        background: `conic-gradient(
          ${color2} 0deg 90deg,
          ${color4} 90deg 180deg,
          ${color3} 180deg 270deg,
          ${color1} 270deg 360deg
        )`
      };
    }
  }
};