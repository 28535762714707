import LogicalReasoning2DImg from "@/components/subject/LogicalReasoning2d/LogicalReasoning2DImg.vue";
export default {
  name: "LogicalReasoning2DQuestion",
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    LogicalReasoning2DImg
  },
  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};